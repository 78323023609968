import './src/styles/global.scss'

export const onClientEntry = () => {
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (!(`IntersectionObserver` in window)) {
        // eslint-disable-next-line no-unused-expressions
        import(`intersection-observer`);
    }
}

const disableStr = `ga-disable-${process.env.GATSBY_GA_PROPERTY_ID}`;
if (document.cookie.indexOf(disableStr) > -1) {
    window[disableStr] = true;
}
window.gaOptOut = function() {
    document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
    window[disableStr] = true;
    alert('Google Analytics wurde für diese Seite deaktiviert.');
}
